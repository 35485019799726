import React from 'react';
import './gardenofpeace.css';

export const WhyThisProject = () => {
  return (
    <div className="page-layout">
      <div className="image-banner">
        <img
          src="images/HomeSlider_1.jpeg"
          alt="Banner"
          className="banner-image"
        />
        <h1 className="banner-text">WHY THIS PROJECT?</h1>
        <div className="gradient-banner-overlay"></div>
      </div>
      
      <div className="content-section">
        <div className="whythis">
          <h1>Preserving Our Legacy: Uniting Our Community</h1>
          <p>For two decades, the Livermore Five Pillar Muslim Cemetery has been a cherished space of remembrance and solace for Northern Californian Muslim communities. 
            However, due to city/county permit regulations, its burial capacity is limited, and with 20-30 Muslim deaths monthly in the greater Bay Area, the need is pressing. 
            We invite you to be a part of this noble cause and contribute in any way you can.</p>
          <h1>Join Our Cause</h1>
          <p>Discover an array of inspiring fundraising activities dedicated to supporting this significant project. 
            We warmly invite you to be part of this transformative endeavor and contribute in any way you can. Stay connected with us as we update this website regularly, sharing upcoming events, engaging activities, and the overall progress of the project. This website will be regularly updated with upcoming events, activities, and, most importantly, the overall progress of the project. 
            By staying connected, you'll witness the impact of your support and the collective effort of our community in achieving our goals.</p>
        </div>
      </div>
    </div>
  );
};
