import React from 'react'

export const FundraisingInfo = () => {
  return (
    <div className='FundraisingInfoPresentation'>
        <style>{'body { background-color: #96ab98; }'}</style>
        <img className='FundraisingPresentation1'
          src="images/GOP_FundraisingInfo_P1.png"
          alt="Fundraising Information Powerpoint Slide 1"
        />
        <img className='FundraisingPresentation2'
          src="images/GOP_FundraisingInfo_P2.png"
          alt="Fundraising Information Powerpoint Slide 2"
        />
        <img className='FundraisingPresentation3'
          src="images/GOP_FundraisingInfo_P3.png"
          alt="Fundraising Information Powerpoint Slide 3"
        />
        <img className='FundraisingPresentation3.5'
          src="images/GOP_FundraisingInfo_P3.5.png"
          alt="Fundraising Information Powerpoint Slide 3.5"
        />
        <img className='FundraisingPresentation4'
          src="images/GOP_FundraisingInfo_P4.png"
          alt="Fundraising Information Powerpoint Slide 4"
        />
        <img className='FundraisingPresentation5'
          src="images/GOP_FundraisingInfo_P5.png"
          alt="Fundraising Information Powerpoint Slide 5"
        />
        <img className='FundraisingPresentation6'
          src="images/GOP_FundraisingInfo_P6.png"
          alt="Fundraising Information Powerpoint Slide 6"
        />
        <img className='FundraisingPresentation7'
          src="images/GOP_FundraisingInfo_P7.png"
          alt="Fundraising Information Powerpoint Slide 7"
        />
    </div>
  )
}
