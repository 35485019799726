import React from 'react';
import './gardenofpeace.css';

export const Gallery = () => {
  const images = [
    "HomeSlider_1.jpeg", "HomeSlider_2.jpeg",
    "HomeSlider_3.jpeg", "HomeSlider_4.jpg",
    "HomeSlider_5.jpg", "HomeSlider_6.jpg",
    "HomeSlider_7.jpg", "HomeSlider_8.jpg",
    "HomeSlider_9.jpg", "HomeSlider_10.jpg",
    "HomeSlider11.jpg", "HomeSlider12.jpg",
    "Gallery_1.jpg", "Gallery_2.jpg",
    "Gallery_3.jpg"
  ];

  return (
    <div>
      <div className="row">
        {images.map((image, index) => (
          <div key={index} className="col-lg-4 col-md-12 mb-4">
            <img src={`images/${image}`} className="w-100 shadow-1-strong rounded mb-4" alt="" />
          </div>
        ))}
      </div>
    </div>
  );
}
