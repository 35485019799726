import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import './gardenofpeace.css';
import Card from './Card';

export const Home = () => {
  const greenTextStyle = {
    color: '#69b32e',
  };

  return (
    <div className="home">
      <img className="main-img" src="images/HomeSlider11.jpg" alt="Background" />
      <div className="gradient-overlay"></div>
      <div className="headings">
        <h1 className="main-heading">EMBRACING ETERNAL REST</h1>
        <h2 className="sub-heading">
          <span style={greenTextStyle}>GARDEN OF PEACE</span> - A PLACE FOR ALL MUSLIMS
        </h2>
        <h2>17205 S Jasper Sears Road, Santa Nella, CA 95322</h2>
        <h2>(669) 214-6771</h2>
        <div>
        <Link to="/donate" className="home-button">
              DONATE <FontAwesomeIcon icon={faArrowRight} />
        </Link>
        </div>
      </div>
      <div className="shadow-box">
          <div className="inner-content">
            <h3>Welcome to Garden of Peace</h3>
            <img className="description-img" src="/images/HomeLocation.png" alt="Location"/>
            <p>Garden of Peace is 501c nonprofit organization dedicated to providing Funeral and Burial Services for all Muslims in Northern California. Our mission is to ensure that every Muslim finds solace in this resting ground. 
              Join us in nurturing a "Garden of Peace" where every soul can rest peacefully, forever united in the embrace of our shared faith.</p>
              <Link to="/aboutus" className="learnmore-button">
              LEARN MORE <FontAwesomeIcon icon={faArrowRight} />
              </Link>
          </div>
        </div>
      <div className='card-info'>
          <Card />
      </div>
    </div>
  );
};
