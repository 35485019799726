import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

var firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyAW7BnuvldzJCp7lqc6ueQPlkIbR8Vq35U",
  authDomain: "garden-of-peace-918c2.firebaseapp.com",
  projectId: "garden-of-peace-918c2",
  storageBucket: "garden-of-peace-918c2.appspot.com",
  messagingSenderId: "688194740541",
  appId: "1:688194740541:web:2115e0bcafd88443c66554",
  measurementId: "G-05FDCGT3J1"
});

var db = firebaseApp.firestore();

export { db };