import React from 'react'

export const Training = () => {
  return (
    <div className='Presentation'>
        <img className='presentation1'
          src="images/Training.jpg"
          alt="Training and Education"
        />
    </div>
  )
}
