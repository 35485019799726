import React from 'react';
import './gardenofpeace.css';

export const DonateZelle = () => {
  return (
    <div className='donatezelle'>
        <p>First and foremost, we would like to express our gratitude for considering making a donation towards Garden of Peace. Your donations will play a significant role in helping us achieve our goal. 
          Every dollar you contribute will go directly towards the construction, operation, and maintenance of the Garden of Peace facility, ensuring that it can serve the Muslim community for years to come.</p>
        <p>If you do decide to donate, we offer three convenient payment methods: PayPal, Zelle, and via P.O. Box.</p>
        <ul>
          <li>
          <b>To donate via PayPal or Zelle, please send your donation to gardenofpeace.usa@gmail.com. </b>
          <p><em>In addition to this, please kindly include your email in the comments section so that we are able to contact you if needed.</em></p>
          </li>
          <li>
          <b>To donate via P.O. box, please send a cheque to P.O. Box 3216, Santa Clara, CA, 95055.</b>
          </li>
        </ul>
        <p>Any amount, no matter how small, will be incredibly helpful and greatly appreciated.</p>
        <p>Once again, we thank you for your time and consideration. Your support will make a meaningful impact on our community, and we are truly grateful for your kindness.</p>
    </div>
  )
}
