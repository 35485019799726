import React, { useState, useRef } from 'react';
import './gardenofpeace.css';
import { db } from './firebase';
import {toast} from 'react-toastify';
import emailjs from '@emailjs/browser'

export const ContactUs = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [number, setNumber] = useState("");
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState(false);

  const combinedSubmitHandler = (e) => {
    handleSubmit(e);
    sendEmail(e);
 };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!name || !email || !number ||!message) {
      toast.error("Please provide a value in each input field");
    } else {
      //db.child("contact-form").push(state);
      //setState({name: "", email: "", phone: "", message: ""});
      toast.success("Form Submitted Successfully");
    }
    setLoader(true);
    db.collection('contact-form').add({
      name: name,
      email: email,
      subject: subject,
      phone: number,
      message: message,
    })
    .then(() => {
      alert('Thank you for filling out the contact form. Your message has been sumbitted.')
      setLoader(false);
    })
    .catch(error => {
      alert(error.message);
      setLoader(false);
    });

    setName("");
    setEmail("");
    setSubject("");
    setNumber("");
    setMessage("");
  }

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_2pg8t3y', 'template_5quw4lm', form.current, 'Ht4x-bJs385J_5CXI')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <div className='contact'>
    <form className="form" onSubmit={combinedSubmitHandler} ref={form}>
      <h1>Contact Us</h1>

      <h4>We welcome your feedback and suggestions. Please fill out the form and we will get back to you shortly. Thank you for your feedback.</h4>
      
      <label>Full Name</label>
      <input required
      placeholder = "Name"
      value = {name}
      onChange={(e) => setName(e.target.value)}
      name='user_name'
      />

      <label>Email</label>
      <input required
      placeholder = "Email"
      value = {email}
      onChange={(e) => setEmail(e.target.value)}
      name='user_email'
      />

    <label>Phone Number</label>
      <input 
      placeholder = "Phone Number"
      value = {number}
      onChange={(e) => setNumber(e.target.value)}
      name='user_phone'
      />

      <label>Subject</label>
      <input 
      placeholder = "Subject"
      value = {subject}
      onChange={(e) => setSubject(e.target.value)}
      name='subject'
      />

      <label>Message</label>
      <textarea 
      placeholder = "Message"
      value = {message}
      onChange={(e) => setMessage(e.target.value)}
      name='message'
      ></textarea>

      <button 
      type="submit"
      style = {{ background: loader ? "#ccc" : "rgb(31, 79, 32)" }}
      >Submit</button>
    </form>
    </div>
  )
}
